import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';

const initialState = {
  data: {},
  loading: {},
  errors: {},
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.data = action.payload;
    },
    fetchUsersSuccess(state, action) {
      state.data = action.payload.reduce((acc, v) => ({
        ...acc,
        [v.user_id]: v,
      }), {});
    },
    fetchUserSuccess(state, action) {
      state.data = {
        ...state.data,
        [action.payload.user_id]: {
          ...(state.data[action.payload.user_id] || {}),
          ...action.payload,
        }
      };
    },
    usersFailure(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    updateLoading(state, action) {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
  },
});

export const {
  setUsers,
  fetchUsersSuccess,
  fetchUserSuccess,
  usersFailure,
  setLoading,
  updateLoading,
} = slice.actions;

const fetchUserReps = (parent_id, parent_type='TENANT', exclude_default_company_users=true, params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({users: true}));
  try {
    const { json } = await oauth('GET', 'user', {
      parent_id, parent_type, exclude_default_company_users, ...params
    });

    if (json && !json.error) {
      const data = _.get(json, 'users', []);
      console.log('call fetch user sucess', json, fetchUsersSuccess);
      dispatch(setUsers(data));
    } else {
      dispatch(usersFailure(getErrorMessageFromError(json.error, 'Cannot get user reps.')));
    }
  } catch (error) {
    console.error('ERROR|fetchUserReps| ', error);
    dispatch(usersFailure(getErrorMessageFromError(error, 'Cannot get team user reps.')));
  }
  dispatch(updateLoading({users: false}));
};

const fetchUsers = (params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({users: true}));
  try {
    const { json } = await oauth('GET', 'user', params);

    const data = _.get(json, 'users', []);
    dispatch(fetchUsersSuccess(data));
  } catch (error) {
    console.error('ERROR|fetchUsers| ', error);
    dispatch(usersFailure(getErrorMessageFromError(error, 'Cannot get users.')));
  }
  dispatch(updateLoading({users: false}));
};

const fetchCompanyUsers = (params={}) => fetchUsers({
  search_type: 'company-users',
  ...params,
});

function getErrorMessageFromError(error, defaultMsg = "Unable to perform this action") {
  if (typeof error === 'string') { return error; }
  if (_.get(error, 'message', false)) {
    return _.get(error, 'message', false);
  }
  if (_.get(error, 'detail', false)) {
    return _.get(error, 'detail', false);
  }
  return defaultMsg;
}

// Exports
export const selectUsers = state => _.get(state, ['users', 'data'], {});
export const selectUsersLoading = state => state.users.loading;
export const selectUsersErrors = state => state.users.errors;
export const selectors = {
    users: createSelector(selectUsers, s => s),
    loading: createSelector(selectUsersLoading, s => s),
    errors: createSelector(selectUsersErrors, s => s),
    options: createSelector(selectUsers, s => Object.values(s).map(v => ({
      label: v.contact_full_name,
      value: v.user_id,
    }))),
    mentionUsers: createSelector(selectUsers, s => Object.values(s)
      .filter(v => !!v.user_mask)
      .map(v => ({...v, mask: v.user_mask}))
    ),
};

export {
  fetchUserReps,
  fetchUsers,
  fetchCompanyUsers,
};

export default slice.reducer;

import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';

const initialState = {
  data: {},
  loading: {},
  errors: {},
};

const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeams(state, action) {
      state.data = action.payload;
    },
    fetchTeamsSuccess(state, action) {
      state.data = action.payload.reduce((acc, v) => ({
        ...acc,
        [v.team_id]: v,
      }), {});
    },
    fetchTeamSuccess(state, action) {
      state.data = {
        ...state.data,
        [action.payload.team_id]: {
          ...(state.data[action.payload.team_id] || {}),
          ...action.payload,
        }
      };
    },
    updateTeamSuccess(state, action) {
      state.data[action.payload.team_id] = {
        ...(state.data[action.payload.team_id] || {}),
        ...action.payload,
      };
    },
    addTeamUserSuccess(state, action) {
      const team = state.data[action.payload.team_id] || {};
      const teamUsers = _.get(team, ['team_users']) || [];
      const foundIdx = teamUsers.findIndex(
        v => v.user_id === action.payload.user_id
      );
      if (foundIdx === -1) {
        state.data[action.payload.team_id] = {
          ...team,
          team_users: [
            ...teamUsers,
            action.payload,
          ],
        };
      }
    },
    deleteTeamUserSuccess(state, action) {
      const team = state.data[action.payload.team_id];
      if (!team) { return; }
      const teamUsers = team.team_users || [];
      if (!teamUsers.length) { return; }
      const foundIdx = teamUsers.findIndex(
        v => v.user_id === action.payload.user_id
      );
      if (foundIdx === -1) { return; }
      state.data[action.payload.team_id] = {
        ...team,
        team_users: [
          ...teamUsers.slice(0, foundIdx),
          ...teamUsers.slice(foundIdx+1),
        ],
      };
    },
    createTeamSuccess(state, action) {
      state.data[action.payload.team_id] = action.payload;
    },
    teamsFailure(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    updateLoading(state, action) {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
  },
});

const fetchTeams = (params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({teams: true}));
  try {
    const { json } = await oauth('GET', 'team', params);

    const data = _.get(json, 'teams', []);
    dispatch(fetchTeamsSuccess(data));
  } catch (error) {
    console.error('ERROR|fetchTeams| ', error);
    dispatch(teamsFailure(getErrorMessageFromError(error, 'Cannot get teams.')));
  }
  dispatch(updateLoading({teams: false}));
};

const fetchTeam = (teamId, params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({teams: true}));
  try {
    const { json } = await oauth('GET', 'team/'+teamId, params);

    const data = _.get(json, 'team', {});
    dispatch(fetchTeamSuccess(data));
  } catch (error) {
    console.error('ERROR|fetchTeams| ', error);
    dispatch(teamsFailure(getErrorMessageFromError(error, 'Cannot get teams.')));
  }
  dispatch(updateLoading({teams: false}));
};

const updateTeam = (teamId, params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({teams: true}));
  try {
    const { json } = await oauth('PUT', 'team/'+teamId, params);

    const data = _.get(json, 'team', {});
    dispatch(updateTeamSuccess(data));
    // dispatch(fetchTeam(teamId));
    return data;
  } catch (error) {
    console.error('ERROR|updateTeam| ', error);
    dispatch(teamsFailure(getErrorMessageFromError(error, 'Cannot update team.')));
  }
  dispatch(updateLoading({teams: false}));
  return null;
};

const addTeamUser = (teamId, userId) => async (dispatch, getState) => {
  dispatch(updateLoading({teams: true}));
  try {
    const { json } = await oauth('POST', 'team-user', { team_id: teamId, user_id: userId });

    const data = _.get(json, 'team_user') || { team_id: teamId, user_id: userId };
    dispatch(addTeamUserSuccess(data));
    return data;
  } catch (error) {
    console.error('ERROR|addTeamUser| ', error);
    dispatch(teamsFailure(getErrorMessageFromError(error, 'Cannot add team user.')));
  }
  dispatch(updateLoading({teams: false}));
  return null;
};

const deleteTeamUser = (teamId, userId) => async (dispatch, getState) => {
  dispatch(updateLoading({teams: true}));
  try {
    await oauth('DELETE', 'team-user', { team_id: teamId, user_id: userId });
    const data = { team_id: teamId, user_id: userId };
    dispatch(deleteTeamUserSuccess(data));
    return data;
  } catch (error) {
    console.error('ERROR|deleteTeamUser| ', error);
    dispatch(teamsFailure(getErrorMessageFromError(error, 'Cannot delete team user.')));
  }
  dispatch(updateLoading({teams: false}));
  return null;
};

const createTeam = (params={}) => async (dispatch, getState) => {
  dispatch(updateLoading({teams: true}));
  try {
    const { json } = await oauth('POST', 'team/', params);

    const data = _.get(json, 'team', {});
    // dispatch(createTeamSuccess(data));
    dispatch(fetchTeam(data.team_id));
    return data;
  } catch (error) {
    console.error('ERROR|createTeam| ', error);
    dispatch(teamsFailure(getErrorMessageFromError(error, 'Cannot create team.')));
  }
  dispatch(updateLoading({teams: false}));
  return null;
};

function getErrorMessageFromError(error, defaultMsg = "Unable to perform this action") {
  if (typeof error === 'string') { return error; }
  if (_.get(error, 'message', false)) {
    return _.get(error, 'message', false);
  }
  if (_.get(error, 'detail', false)) {
    return _.get(error, 'detail', false);
  }
  return defaultMsg;
}

// Exports
export const selectTeams = state => _.get(state, ['teams', 'data'], {});
export const selectTeamsList = createSelector(
  selectTeams,
  s => Object.values(s)
);
export const selectTeamsLoading = state => state.teams.loading;
export const selectTeamsErrors = state => state.teams.errors;
export const selectors = {
  teams: createSelector(selectTeams),
  loading: createSelector(selectTeamsLoading),
  errors: createSelector(selectTeamsErrors),
  byId: createSelector([selectTeams, (s, teamId) => teamId], (s, teamId) => teamId ? s[teamId] : null),
  options: createSelector(selectTeams, s => Object.values(s).map(v => ({
    label: v.team_name,
    value: v.team_id,
    team_type: v.team_type,
    users: v.team_users,
  }))),
};

export const {
  setTeams,
  fetchTeamsSuccess,
  fetchTeamSuccess,
  updateTeamSuccess,
  createTeamSuccess,
  addTeamUserSuccess,
  deleteTeamUserSuccess,
  teamsFailure,
  setLoading,
  updateLoading,
} = slice.actions;

export {
  fetchTeams,
  fetchTeam,
  updateTeam,
  createTeam,
  addTeamUser,
  deleteTeamUser
};

export default slice.reducer;

import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';
import _ from 'lodash';

import { UPDATE_REMINDER_SUCCESS, FETCH_REMINDERS_SUCCESS, UPDATE_NOTE_SUCCESS, ADD_MESSAGE_SUCCESS ,UPDATE_MESSAGE_SUCCESS,DELETE_MESSAGE_SUCCESS,ADD_COMMENT_SUCCESS} from '../actions/message';
import {
    FETCH_PO_FOR_PRODUCTION_SUCCESS, UPDATE_PURCHASE_ORDER_REQUEST, UPDATE_PURCHASE_ORDER_SUCCESS,
    UPDATE_PURCHASE_ORDER_FAILURE, FETCH_SUPPLIER_CONTACT_SUCCESS, FETCH_SHIPMENTS_SUCCESS,
    FETCH_APPEND_POS_FOR_PRODUCTION_SUCCESS,
} from '../actions/purchase_order';
import { FETCH_PO_PROOF_SUCCESS, UPLOAD_PO_PROOF_SUCCESS, UPDATE_PO_PROOF_SUCCESS, DELETE_PO_PROOF_SUCCESS} from '../actions/purchase_order_proof';
import { ADD_PROOF_COMMENT_SUCCESS } from '../actions/comment';
import { FETCH_JOB_FOR_PRODUCTION_SUCCESS, CREATE_REPORT_VIEW_SUCCESS, UPDATE_REPORT_VIEW_SUCCESS, DELETE_REPORT_VIEW_SUCCESS, CLEAR_PURCHASE_ORDERS } from '../actions';
import { UPLOAD_FILE_SUCCESS, DELETE_FILE_SUCCESS } from '../actions/file';

import displayReducer from '../reducers/display';
import {
    bookmarksReducer, notificationsReducer, recentOrdersReducer, remindersReducer,
    notificationCountReducer,
} from '../reducers/header';
import fileReducer from '../reducers/file';
import { addressReducer, contactReducer, eventTypeReducer, dropdownsReducer } from '../reducers/core';
import companyContactReducer from '../reducers/company_contact';

import { toTimestamp } from '../utils';

import { window } from '../global';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;

const identityReducer = (state = initialState.identity, action) => {
    return state;
};


export const folderReducer = (state, action) => {
    let folders;

    switch(action.type) {
        case FETCH_JOB_FOR_PRODUCTION_SUCCESS:
            folders = action.payload.folders;
            return { ...state, ...folders };
    }

    return state;
};

export const proofReducer = (state, action) => {
    let purchase_order_id;
    let po_proofs;
    let po_proof;
    let comment;
    let purchase_order_proof_id;

    switch(action.type) {
        case UPLOAD_PO_PROOF_SUCCESS:
            po_proof = action.payload.purchase_order_proof;
            purchase_order_id = po_proof.purchase_order_id;

            return {
                ...state,
                [purchase_order_id]: {
                    ...state[purchase_order_id],
                    po_proofs: {
                        ...state[purchase_order_id].po_proofs,
                        [po_proof.purchase_order_proof_id]: po_proof
                    }
                }
            };
        case UPDATE_PO_PROOF_SUCCESS:
            po_proof = action.payload.purchase_order_proof;
            purchase_order_id = po_proof.purchase_order_id;

            return {
                ...state,
                [purchase_order_id]: {
                    ...state[purchase_order_id],
                    po_proofs: {
                        ...state[purchase_order_id].po_proofs,
                        [po_proof.purchase_order_proof_id]: {
                            ...state[purchase_order_id].po_proofs[po_proof.purchase_order_proof_id],
                            ...po_proof
                        }
                    }
                }
            };
        case DELETE_PO_PROOF_SUCCESS:
            po_proof = action.payload.purchase_order_proof;
            purchase_order_id = po_proof.purchase_order_id;

            po_proofs = _.filter(state[purchase_order_id].po_proofs, (proof) => {
                return proof.purchase_order_proof_id !== po_proof.purchase_order_proof_id;
            });

            return {
                ...state,
                [purchase_order_id]: {
                    ...state[purchase_order_id],
                    po_proofs
                }
            };
        case ADD_PROOF_COMMENT_SUCCESS:
            comment = action.payload.comment;
            purchase_order_proof_id = action.payload.comment.parent_id;
            purchase_order_id = action.payload.comment.purchase_order_id;

            return {
                ...state,
                [purchase_order_id]: {
                    ...state[purchase_order_id],
                    po_proofs: {
                        ...state[purchase_order_id].po_proofs,
                        [purchase_order_proof_id]: {
                            ...state[purchase_order_id].po_proofs[purchase_order_proof_id],
                            comments: {
                                ...state[purchase_order_id].po_proofs[purchase_order_proof_id].comments,
                                [comment.comment_id]: comment
                            }
                        }
                    }
                }
            };
        case FETCH_PO_PROOF_SUCCESS:
            purchase_order_id = action.payload.purchase_order_id;
            po_proofs = action.payload.purchase_order_proofs;
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { po_proofs: po_proofs }) });
    }

    return state;
};

export const jobReducer = (state, action) => {
    let job;

    switch(action.type) {
        case FETCH_JOB_FOR_PRODUCTION_SUCCESS:
            job = action.payload.job;
            return { ...state, [job.job_id]: job };
    }

    return state;
};

export const purchaseOrderReducer = (state, action) => {
    let purchase_orders;
    let updated_po;
    let purchase_order_id;
    let po_proof;

    switch (action.type) {
        case FETCH_APPEND_POS_FOR_PRODUCTION_SUCCESS:
        case FETCH_PO_FOR_PRODUCTION_SUCCESS:
            purchase_orders = action.payload.purchase_orders || [];
            purchase_orders = purchase_orders.reduce((o, p) => ({
                ...o,
                [p.purchase_order_id]: {
                    ...p,
                    po_firm: +p.firm === 1 ? 'Yes' : 'No',
                    promostandards: 'promostandards',
                },
            }), {});
            if (action.type === 'FETCH_PO_FOR_PRODUCTION_SUCCESS') {
                return purchase_orders;
            }
            return {
                ...state,
                ...purchase_orders,
            };
        case UPDATE_PURCHASE_ORDER_REQUEST:
        case UPDATE_PURCHASE_ORDER_SUCCESS:
        case UPDATE_PURCHASE_ORDER_FAILURE:
            purchase_order_id = action.payload.id;
            updated_po = Object.assign({}, state[purchase_order_id], action.payload.data);
            return Object.assign({}, state, {[purchase_order_id]: updated_po});
        case UPLOAD_PO_PROOF_SUCCESS:
        case UPDATE_PO_PROOF_SUCCESS:
            po_proof = action.payload.purchase_order_proof;
            updated_po = action.payload.purchase_order;
            purchase_order_id = po_proof.purchase_order_id;

            return {
                ...state,
                [purchase_order_id]: {
                    ...state[purchase_order_id],
                    ...updated_po,
                    proof_status_name: po_proof.status.status_name
                }
            };
        case DELETE_PO_PROOF_SUCCESS:
            updated_po = action.payload.purchase_order;
            purchase_order_id = updated_po.purchase_order_id;
            return {
                ...state,
                [purchase_order_id]: {
                    ...state[purchase_order_id],
                    ...updated_po
                }
            };
        case CLEAR_PURCHASE_ORDERS:
            return {};
    }

    return state;
};

export const reminderListReducer = (state, action) => {
    let message_id;
    let note;
    let purchase_order_id;
    let notes;
    let data;
    let reminder;
    let list;
    let updated_list;
    let files;
    let original_list;
    let comment;
    let replies;
    switch(action.type) {
        case UPDATE_REMINDER_SUCCESS:
            if(!action.payload.id) {
                return state;
            }

            message_id = action.payload.id;
            note = action.payload.note;
            purchase_order_id = note.parent_id;
            reminder = Object.assign({}, state[purchase_order_id]['reminder_list'][message_id], note);
            list = Object.assign({}, state[purchase_order_id]['reminder_list'], { [message_id]: reminder });
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { reminder_list: list })  });
        case FETCH_REMINDERS_SUCCESS:
            if (!action.payload.id) {
                return state;
            }
            purchase_order_id = action.payload.id;
            notes = action.payload.notes;
            updated_list = notes.reduce((o, note) => {
                o[note.message_id] = {...(note.note || note), latest_update: note.latest_update, pinned: note.pinned };
                o[note.message_id].replies = note.replies || [];
                o[note.message_id].actor =  note.actor || [];
                return o;
            }, {});
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { reminder_list: updated_list }) });
        case UPDATE_NOTE_SUCCESS:
            message_id = action.payload.id;
            purchase_order_id = action.payload.parent_id;
            data = action.payload.data;

            reminder = Object.assign({}, state[purchase_order_id]['reminder_list'][message_id], data);
            list = Object.assign({}, state[purchase_order_id]['reminder_list'], { [message_id]: reminder });
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { reminder_list: list }) });
        case UPDATE_MESSAGE_SUCCESS:
            purchase_order_id= action.payload.job_id;
            message_id =action.payload.parent_id;
            reminder = Object.assign({}, state[purchase_order_id]['reminder_list'][message_id], {pinned:action.payload.pinned});
            list = Object.assign({}, state[purchase_order_id]['reminder_list'], { [message_id]: reminder });
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { reminder_list: list  }) });
        case DELETE_MESSAGE_SUCCESS:
            message_id = action.payload.id;
            purchase_order_id= action.payload.job_id;
            original_list = state[purchase_order_id]['reminder_list'];
            list = Object.keys(original_list).reduce((accum,key)=>{
                if(key != message_id){
                    accum[key] = original_list[key];
                }return accum;
            },{});
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({},state[purchase_order_id],{ reminder_list: list }) });
        case ADD_MESSAGE_SUCCESS:
            note = action.payload.message;
            message_id = note.message_id;
            purchase_order_id = action.payload.details_parent_id;
            files = action.payload.files;
            list = Object.assign({}, state[purchase_order_id]['reminder_list'], {
                [message_id]: {
                    message_id: message_id,
                    note_id: note.note_id,
                    message_text: note.message_text,
                    date_reminder: note.date_reminder,
                    reminder_user: note.reminder_user ? note.reminder_user.user_id : null,
                    reminder_complete: note.reminder_complete,
                    date_complete: note.date_complete,
                    reminder_user_first_name: note.reminder_user ? note.reminder_user.user_first_name : null,
                    reminder_user_last_name: note.reminder_user ? note.reminder_user.user_last_name : null,
                    created_by: note.user_id,
                    creator_user_first_name: note.user_first_name,
                    creator_user_last_name: note.user_last_name,
                    date_created: note.date_created,
                    latest_update: note.latest_update,
                    pinned:note.pinned,
                    parent_id:note.note_parent_id,
                    file: files,
                    user_image_paths:note.user_image_paths
                }
            });
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { reminder_list: list }) });
        case ADD_COMMENT_SUCCESS:
            note = action.payload.message;
            message_id = note.parent_id;
            purchase_order_id = action.payload.params.purchase_order_id;
            comment = Object.assign({},note,{
                actor:{
                    user_id: note.user_id,
                    user_image_paths: note.user_image_paths,
                    user_first_name:note.user_first_name,
                    user_last_name:note.user_last_name
                },
                date:note.date_created,
                text:note.message_text,
                original_parent_id: note.parent_id,
                original_parent_type: note.parent_type.toLowerCase(),
            });
            replies = state[purchase_order_id]['reminder_list'][message_id].replies || [];
            reminder = Object.assign({},state[purchase_order_id]['reminder_list'][message_id],
            {replies: replies.concat(comment)});

            list = Object.assign({}, state[purchase_order_id]['reminder_list'], {[message_id]:reminder});
            return Object.assign({}, state, { [purchase_order_id]: Object.assign({}, state[purchase_order_id], { reminder_list: list })});

        }

    return state;
};

const reportViewReducer = (state, action) => {
    let report_view;
    let report_view_id;

    switch(action.type) {
        case CREATE_REPORT_VIEW_SUCCESS:
        case UPDATE_REPORT_VIEW_SUCCESS:
            report_view = action.payload.report_view;
            return { ...state, [report_view.report_view_id]: report_view };
        case DELETE_REPORT_VIEW_SUCCESS:
            report_view_id = action.payload.report_view_id;
            return Object.values(state).filter(v => v.report_view_id !== report_view_id).reduce((o, v) => { o[v.report_view_id] = v; return o; }, {});
    }

    return state;
};

export const shipmentReducer = (state, action) => {
    let purchase_order_id;
    let shipments;

    switch (action.type) {
        case FETCH_SHIPMENTS_SUCCESS:
            purchase_order_id = action.payload.purchase_order_id;
            shipments = action.payload.shipments;
            return { ...state, [purchase_order_id]: shipments.reduce((o, s) => { o[s.shipment_id] = s; return o; }, {}) };
    }

    return state;
};

export const supplierAccountReducer = (state, action) => {
    let supplier_account_id;
    let contact;

    switch(action.type) {
        case FETCH_SUPPLIER_CONTACT_SUCCESS:
            supplier_account_id = action.payload.supplier_account_id;
            contact = action.payload.data;

            return {
                ...state,
                [supplier_account_id]: {
                    ...state[supplier_account_id],
                    ...contact
                }
            };
    }

    return state;
};

const entitiesReducer = (state = initialState.entities, action) => {
    const new_state = Object.assign({}, state, {
        files: fileReducer(state.files, action),
        folders: folderReducer(state.folders, action),
        po_proofs: proofReducer(state.po_proofs, action),
        projects: jobReducer(state.projects, action),
        purchase_orders: purchaseOrderReducer(state.purchase_orders, action),
        reminder_lists: reminderListReducer(state.reminder_lists, action),
        report_views: reportViewReducer(state.report_views, action),
        bookmarks: bookmarksReducer(state.bookmarks, action),
        recent_orders: recentOrdersReducer(state.recent_orders, action),
        reminders: remindersReducer(state.reminders, action),
        notifications: notificationsReducer(state.notifications, action),
        notification_count: notificationCountReducer(state.notification_count, action),
        shipments: shipmentReducer(state.shipments, action),
        supplier_accounts: supplierAccountReducer(state.supplier_accounts, action),
        event_types: eventTypeReducer(state.event_types || {}, action),
        addresses: addressReducer(state.addresses || {}, action),
        contacts: contactReducer(state.contacts || {}, action),
        company_contacts: companyContactReducer(state.company_contacts || {}, action),
    });
    return new_state;
};

export const reducers = {
    display: displayReducer,
    dropdowns: dropdownsReducer,
    entities: entitiesReducer,
    identity: identityReducer
};

const rootReducer = combineReducers(reducers);

export default function configureProductionReportStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
